module.exports = {
  //-- SITE SETTINGS -----
  author: "madoff.finance",
  siteTitle: "Ponzi Token",
  siteShortTitle: "Ponzi Token", // Used as logo text in header, footer, and splash screen
  siteDescription: "",
  siteUrl: "https://madoff.finance",
  siteLanguage: "en_US",
  siteIcon: "content/logo.png", // Relative to gatsby-config file
  seoTitleSuffix: "Ponzi Token", // SEO title syntax will be e.g. "Imprint - {seoTitleSuffix}"
  useCookieBar: false, // If you use Google Analytics and want to be GDPR-compliant, set it to true
  googleAnalyticsTrackingId: "", // e.g. UA-XXXXXX-X

  // -- THEME SETTINGS -----
  colors: {
    lightTheme: {
      primary: "#000000",
      secondary: "rgba(238,186,170,0.8)",
      tertiary: "rgba(213,97,154,0.5)",
      text: "#000000",
      subtext: "#555555",
      background: "rgb(244,244,244)",
      card: "#FFFFFF",
      scrollBar: "rgba(0, 0, 0, 0.5)",
      boxShadow: "rgba(0, 0, 0, 0.16)",
      boxShadowHover: "rgba(0, 0, 0, 0.32)",
    },
    darkTheme: {
      primary: "#FAFAFA",
      secondary: "rgba(238,186,170,0.8)",
      tertiary: "rgba(213,97,154,0.5)",
      text: "rgba(255, 255, 255, 0.87)",
      subtext: "#AAAAAA",
      background: "#121212",
      card: "#1C1C1C",
      scrollBar: "rgba(255, 255, 255, 0.5)",
      boxShadow: "rgba(0, 0, 0, 0.16)",
      boxShadowHover: "rgba(0, 0, 0, 0.32)",
    },
  },
  fonts: {
    primary: "Inter",
  },

  //-- ARTICLES SECTION SETTINGS -----
  // You can create your own Medium feed with this rss to json converter: https://rss2json.com/
  // To access your Medium RSS feed, just replace this url with your username: https://medium.com/feed/@{yourname}
  mediumRssFeed:
    "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40konstantinmuenster",
  // rssFeed: "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.theguardian.com%2Finternational%2Frss",

  shownArticles: 3,

  //-- SOCIAL MEDIA SETTINGS -----
  // There are icons available for the following platforms:
  // Medium, GitHub, LinkedIn, XING, Behance, E-Mail
  socialMedia: [
    {
      name: "twitter",
      url: "https://twitter.com/ercponzi",
    },
    {
      name: "telegram",
      url: "http://t.me/PonziErc20",
    },
    // {
    //   name: "Mail",
    //   url: "mailto:mail@konstantin.digital",
    // },
  ],

  contractAddress: [
    {
      address: '0xfc573fc809dfb5dfd2872ce9b634c055cc00a9f8',
      url: 'https://etherscan.io/token/0xfc573fc809dfb5dfd2872ce9b634c055cc00a9f8'
    }
  ],

  //-- NAVIGATION SETTINGS -----
  navLinks: {
    menu: [],
    button: null,
    socials: [
      {
        name: "twitter",
        url: "https://twitter.com/ercponzi",
      },
      {
        name: "telegram",
        url: "http://t.me/PonziErc20",
      },
    ],
  },
  footerLinks: [
    {
      name: "Privacy",
      url: "/privacy",
    },
    {
      name: "Imprint",
      url: "/imprint",
    },
  ],
}
