import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import LogoHeader from "../assets/ponzi/41.png"

const StyledLogo = styled.img`
  position: relative;
  z-index: 13;
  width: 75px;
  height: 75px;

  /* Disable effects when sidebar is open */
  filter: none !important;
  pointer-events: auto !important;
  user-select: auto !important;
`

const Logo = ({ size, color }) => <StyledLogo src={LogoHeader} />

Logo.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
}

export default Logo
